.title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}
